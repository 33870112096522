import React, { useContext, useState, useEffect } from 'react'
import MaskedInput from 'react-maskedinput'
import navigate from '../utils/navigate'
import { Context as LeaveContext } from '../components/LeaveContext'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PrivateRoute from '../components/PrivateRoute'
import LeaveRequestQuickNav from '../components/LeaveRequestQuickNav'
import validateDate from '../utils/validateDate'
import validateYesNo from '../utils/validateYesNo'
import getAnimationStyle from '../utils/getAnimationStyle'

export default function New_Leave_Request_Covid_EE() {
  const { Covid, covidComplete, finishCovid } = useContext(LeaveContext)

  const [state, setState] = useState({
    employeeFullyVaccinated: null,
    employeeHasSymptoms: null,
    symptomStartDate: null,
    covidTestResultsList: [
      { Value: 1, Name: 'Pending' },
      { Value: 2, Name: 'Positive' },
      { Value: 3, Name: 'Negative' },
    ],
    employeeCovidTestResultID: null,
    employeeLastDayOfWork: null,
    employeeTestedCovid: null,
    Error: {},
  })

  useEffect(() => {
    if (!Covid) {
      navigate('/Overview/');
    } else if (covidComplete) {
      setState(prev => ({
        ...prev,
        employeeFullyVaccinated: Covid.employeeFullyVaccinated,
        employeeHasSymptoms: Covid.employeeHasSymptoms,
        symptomStartDate: Covid.symptomStartDate,
        employeeCovidTestResultID: Covid.employeeCovidTestResultID,
        employeeLastDayOfWork: Covid.employeeLastDayOfWork,
        employeeTestedCovid: Covid.employeeTestedCovid,
      }))
    }
  }, [Covid, covidComplete])

  const onSubmit = (event) => {
    event.preventDefault()

    const Errors = {}
    // Validate Data
    if (!validateYesNo(state.employeeFullyVaccinated)) Errors.employeeFullyVaccinated = 'Select if Employee is Fully Vaccinated'
    if (!validateYesNo(state.employeeHasSymptoms)) Errors.employeeHasSymptoms = 'Select if Employee has Symptoms'

    if (state.employeeHasSymptoms === 'Yes') {
      if (!validateYesNo(state.employeeTestedCovid)) Errors.employeeTestedCovid = 'Select if Employee has Tested for Covid'
      if (!validateDate(state.symptomStartDate)) Errors.symptomStartDate = 'Symptom Start Date is not a valid date (MM/DD/YYY)'
      if (!validateDate(state.employeeLastDayOfWork)) Errors.employeeLastDayOfWork = 'Last Day Of Work is not a valid date (MM/DD/YYY)'
      if (state.employeeTestedCovid === 'Yes') {
        if (!validateCovidTestResult()) Errors.employeeCovidTestResultID = 'Please select a Covid Test Result'
      }
    }

    if (Object.keys(Errors).length > 0) {
      setState(prev => ({ ...prev, Error: Errors }))
      return false
    }

    // Remove errors before saving to the store
    const covid = { ...state }
    delete covid.Error

    // Save to the store
    finishCovid(covid)
    if (state.employeeHasSymptoms === 'No' && (parseInt(state.employeeCovidTestResultID, 10) === 3 || state.employeeTestedCovid === 'No')) {
      navigate('/New_Leave_Request_Covid_Result_No/');
    } else {
      navigate('/New_Leave_Request_Covid_Result_Yes/');
    }
  }

  const handleStateInput = (e, field) => {
    e.persist()
    const { Error } = state
    delete Error[field]
    setState((prev) => {
      const rtn = { ...prev, Error }
      rtn[field] = e.target.value
      return rtn
    })
  }

  const validateCovidTestResult = (value = state.employeeCovidTestResultID) => value !== '-1' && value !== ''
  return (
    <PrivateRoute>
      <Layout activateLink>
        <SEO title="Covid Leave Request Employee" />
        <div className="content-outline-998 leaveForm">
          <LeaveRequestQuickNav />
          <p>&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <div className="questionhdr">
            <p>Please complete the following questions to determine if you  need to file a COVID leave request. Based on your responses, we will notify  your manager if you can return to work immediately or need time off.</p>
          </div>
          <form className="LeaveFormEntry" onSubmit={onSubmit}>
            <h3>Employee Request Questions:</h3>
            <div className={state.Error.employeeFullyVaccinated ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(0)}>
              <label>
                Are  you fully vaccinated (it has been more than 2 weeks since your final shot)? &nbsp;&nbsp;<br />
              </label>
              <select
                name="EEQ1"
                id="EEQ1"
                style={{ width: '7em' }}
                onChange={e => handleStateInput(e, 'employeeFullyVaccinated')}
                value={state.employeeFullyVaccinated}
              >
                <option value="" />
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <br className="clear" />
            <div className={state.Error.employeeHasSymptoms ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(1)}>
              <label>
                Are you experiencing any of the following symptoms: Fever, chills, cough, sore throat, shortness of breath, difficulty breathing, fatigue, muscle or body aches, headache, loss of taste or smell? &nbsp;&nbsp;
              </label>
              <select
                name="EEQ2"
                id="EEQ2"
                style={{ width: '7em' }}
                onChange={e => handleStateInput(e, 'employeeHasSymptoms')}
                value={state.employeeHasSymptoms}
              >
                <option value="" />
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <br className="clear" />
            <div className={state.Error.symptomStartDate ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(2)}>
              <label>What date did symptoms start? </label>
              <MaskedInput
                mask="11/11/1111"
                placeholder="MM/DD/YYYY"
                type="text"
                name="EEQ3"
                id="EEQ3"
                style={{ width: '8.5em' }}
                value={state.symptomStartDate}
                onChange={e => handleStateInput(e, 'symptomStartDate')}
              />
            </div>
            <br className="clear" />
            <div className={state.Error.employeeTestedCovid ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(3)}>
              <label>Have you tested for COVID? </label>
              <select
                name="EEQ4"
                id="EEQ4"
                style={{ width: '2.25em' }}
                onChange={e => handleStateInput(e, 'employeeTestedCovid')}
                value={state.employeeTestedCovid}
              >
                <option value="" />
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            {state.employeeTestedCovid === 'Yes' && (
              <>
                <br className="clear" />
                <div className={state.Error.employeeCovidTestResultID ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(4)}>
                  <label>What were the results? </label>
                  <select
                    name="EEQ5"
                    id="EEQ5"
                    style={{ width: '8em' }}
                    onChange={e => handleStateInput(e, 'employeeCovidTestResultID')}
                    value={state.employeeCovidTestResultID}
                  >
                    <option value="-1">Please Select</option>
                    {state.covidTestResultsList.map(request => <option key={request.Name} value={request.Value}>{request.Name}</option>)}
                  </select>
                </div>
              </>
            )}
            <br className="clear" />
            <div className={state.Error.employeeLastDayOfWork ? 'error formDiv' : 'formDiv'} style={getAnimationStyle(5)}>
              <label>When was your last day of work?</label>
              <MaskedInput
                mask="11/11/1111"
                placeholder="MM/DD/YYYY"
                type="text"
                name="EEQ6"
                id="EEQ6"
                style={{ width: '8.5em' }}
                value={state.employeeLastDayOfWork}
                onChange={e => handleStateInput(e, 'employeeLastDayOfWork')}
              />
            </div>
            <p className="clear">&nbsp;</p>
            <input type="submit" id="setp1submit" value="Continue &raquo;" />
          </form>
        </div>
      </Layout>
    </PrivateRoute>
  )
}
